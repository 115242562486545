/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./login.css";
// import crs from "../../assets/images/cross/crs.png"
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { Grid, Paper, Typography, Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import { Form, Button } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { userLogin, verifyOtp } from "../../config/service";
import SupportChat from "../supportChat/supportChat";
import RxContractModal from "../DoctorDashboard/RxContractModal";
export const handleKeyPress = (event) => {
  if (event.key === "+" || event.key === "-" || event.key === ".") {
    event.preventDefault();
  }
};

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  // const mode = localStorage.getItem("mode");
  const [formValue, setformValue] = React.useState({
    id_number: "",
    mobile: "",
    password: "",
  });
  const [idError, setIdError] = useState("");
  const [mobileError, setmobileError] = useState("");
  // const [passwordError, setPasswordError] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [open, setOpen] = React.useState(false);
  const [disablebutton, setDisableButton] = useState(false);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isResendDisable, setIsResendDisable] = useState(true);
  const [isIDFMode, setIsIDFMode] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    let client = {
      phone: formValue.mobile,
      id: formValue.id_number,
      key: "client",
    };
    let doctor = {
      phone: formValue.mobile,
      id: formValue.id_number,
      key: "doctor",
    };

    if (validate()) {
      setLoading(true);
      try {
        const response = await userLogin(flag ? doctor : client);
        if (
          doctor &&
          response?.data?.code === 401 &&
          response?.data?.msg === "Contract signature required"
        ) {
          setContract(true);
          return;
        }
        toast.success(t("An OTP sent to your mobile number"));
        setOpen(true);
      } catch (error) {
        console.error(error);
        // Handle the error here, if needed
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUserConfirmationPhone = (e) => {
    const { name, value } = e.target;
    if (
      value.startsWith("+") &&
      !isNaN(value.substring(1, value.length)) &&
      !value.endsWith(".")
    ) {
      setformValue({
        ...formValue,
        [name]: value.trim(),
      });
    } else if (!isNaN(value) && !value.endsWith(".")) {
      setformValue({
        ...formValue,
        [name]: value,
      });
    }
  };
  const OtpValidation = async (e) => {
    e.preventDefault();
    // store the states in the form data
    const role = flag ? "doctor" : "client";
    let data = {
      phone: formValue.mobile,
      otp: otp,
      role: role,
      id: formValue.id_number,
    };

    if (validate()) {
      try {
        // make axios post request
        setDisableButton(true);
        const response = await verifyOtp(data);
        toast.success(t(response.data.msg));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        const decodedToken = jwt_decode(response.data.token);
        localStorage.setItem("userName", decodedToken.name);
        localStorage.setItem("userId", decodedToken.id);
        localStorage.setItem("role", decodedToken.role);
        window.gtag("event", "login-success", {});
        if (decodedToken.role === "admin") {
          navigate("/AdminDashboard");
        } else {
          navigate("/Queues");
        }
        setOpen(false);
        setFlag(false);
        setDisableButton(false);
      } catch (error) {
        setDisableButton(false);
        setOtp();
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (!flag && name === "id_number") {
      const valueUpperCase = value.toUpperCase();
      setformValue({
        ...formValue,
        [name]: valueUpperCase,
      });
    } else
      setformValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
  };
  const validate = () => {
    let isValid = true;

    if (!formValue.id_number) {
      isValid = false;
      setIdError(t("Please Enter Id Number"));
    } else {
      setIdError("");
    }
    if (!formValue.mobile) {
      isValid = false;

      setmobileError(t("Please enter your phone number"));
    }
    return isValid;
  };

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            setIsResendDisable(false);
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [open]);

  useEffect(() => {
    if (mode) {
      if (mode === "IDF") {
        setIsIDFMode(true);
      } else {
        setIsIDFMode(false);
      }
    }
  }, [mode]);

  return (
    <div className={`auth-page ${isIDFMode ? "idf-auth" : ""}`}>
      <div className="newRegd">
        <div>
          <div className="signTop">
            <h2>{t("Connection")}</h2>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="loginToggle">
              {!isIDFMode ? (
                <>
                  <span
                    onClick={() => setFlag(true)}
                    className={`${flag ? "active-tab" : ""}`}
                  >
                    {t("Login as Doctor")}
                  </span>

                  <span
                    onClick={() => setFlag(false)}
                    className={`${!flag ? "active-tab" : ""}`}
                  >
                    {t("Login as Client")}
                  </span>
                </>
              ) : null}
            </div>

            <Form.Group className="form-group ">
              <Form.Control
                name="id_number"
                type={flag ? "number" : "text"}
                onWheel={(e) => e.target.blur()}
                onChange={handleChange}
                id="id_input"
                placeholder={t("ID Number")}
                onKeyPress={handleKeyPress}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 14))}
                autocomplete="off"
              />
            </Form.Group>
            <span className="errorsmg">{t(`${idError}`)}</span>
            <Form.Group className="form-group ">
              <Form.Control
                id="mobile_input"
                type="text"
                placeholder={t("Mobile Number")}
                name="mobile"
                onChange={handleUserConfirmationPhone}
                value={formValue?.mobile ? formValue?.mobile : ""}
                autocomplete="off"
              />
            </Form.Group>
            <span className="errorsmg">{t(`${mobileError}`)}</span>
            <Button type="submit" onClick={handleSubmit} disabled={loading}>
              {t("Login Text")}
            </Button>
          </Form>
          <div className="google-link">
            <h6>
              {" "}
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
              apply.
            </h6>
          </div>
          <hr
            style={{
              background: "black",
              color: "lime",
              borderColor: "black",
              height: "1px",
              width: "100%",
            }}
          />

          {!isIDFMode ? (
            <div
              className="lastDiv "
              onClick={() =>
                navigate(flag ? "/DoctorRegistration" : "/meetingrequest")
              }
            >
              {t("Create an account")}
            </div>
          ) : null}
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ marginTop: "10%" }}
            >
              <Form onSubmit={OtpValidation}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs>
                    <Paper className="paper" square>
                      <Grid container direction="column" alignItems="center">
                        <Grid item lg>
                          <Typography variant="h4" gutterBottom>
                            {t("Please Enter the OTP to Verify Your Account")}
                          </Typography>
                        </Grid>
                        <Grid item sm>
                          <Typography variant="h6" gutterBottom>
                            <span>{formValue.mobile} </span>
                            {t("An OTP(one time password) has been sent to")}
                          </Typography>
                        </Grid>

                        <Grid item xs>
                          <OtpInput
                            value={otp}
                            onChange={(otp) => {
                              setOtp(otp);
                            }}
                            shouldAutoFocus={true}
                            numInputs={6}
                            inputStyle={{
                              fontSize: "24px",
                              width: "30px",
                              height: "30px",
                              margin: "4px",
                              borderTop: "0px",
                              borderLeft: "0px",
                              borderRight: "0px",
                              outline: "none",
                              borderColor: "#000",
                            }}
                            containerStyle={{
                              margin: "20px auto",
                              padding: "10px",
                            }}
                            isInputNum
                            className="otp_number input_font"
                            whenEntered={true}
                          />
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                          <Grid
                            item
                            xs
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "10px",
                            }}
                          >
                            <Button
                              disabled={disablebutton}
                              type="submit"
                              className="submit"
                              onClick={OtpValidation}
                              style={{ maxWidth: "190px", margin: "0px auto" }}
                            >
                              {t("Validate OTP")}
                            </Button>
                            <Grid
                              item
                              sm
                              style={{
                                padding: "15px 0px",
                                margin: "0px auto",
                              }}
                            >
                              <Typography variant="h6">
                                {isResendDisable ? (
                                  <>
                                    <span>{seconds}</span>
                                  </>
                                ) : (
                                  t("")
                                )}
                              </Typography>
                            </Grid>
                            <Button
                              display="block"
                              gutterBottom
                              className="submit"
                              onClick={handleSubmit}
                              disabled={isResendDisable}
                              style={{ maxWidth: "190px", margin: "0px auto" }}
                            >
                              {t("Resend OTP")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
      <SupportChat />
      <RxContractModal show={contract} setContract={setContract} />
    </div>
  );
};
export default Login;
